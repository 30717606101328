import Image from 'next/image'
import { useEffect, useRef, useState } from 'react'
import useBreakpoints from 'src/hooks/useBreakpoints'
import Whatsapp from '../../public/svgs/whatsapp-icon.svg'

type IProps = {
  mobileBottom?: string
}

const WhatsappButton = ({ mobileBottom }: IProps) => {
  const { isUpMd } = useBreakpoints()
  const [isExpanded, setExpanded] = useState(false)
  const [isVisible, setIsVisible] = useState(false)

  const buttonRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || document.documentElement.scrollTop
      setIsVisible(scrollY > 200)
      setExpanded(false)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isExpanded, isUpMd])

  useEffect(() => {
    const handleTouchMove = () => setExpanded(false)
    window.addEventListener('touchmove', handleTouchMove)
    return () => {
      window.removeEventListener('touchmove', handleTouchMove)
    }
  }, [isExpanded, isUpMd])

  const handleMobileClick = () => {
    if (isUpMd) return
    setExpanded((prev) => !prev)
  }

  const handleDesktopHover = (expand: boolean) => () => {
    if (!isUpMd) return
    setExpanded(expand)
  }

  return (
    <div
      className={`${mobileBottom ? mobileBottom : 'bottom-4'} right-4 z-10 md:bottom-8 md:right-8 ${
        isVisible ? 'fixed' : 'hidden'
      } transition-opacity duration-300`}
    >
      <div
        ref={buttonRef}
        className={`flex items-center space-x-2 bg-green-500 ${
          isExpanded ? 'px-4 py-2' : 'p-2'
        }  cursor-pointer rounded-full transition-all duration-300 ${
          isExpanded ? 'md:w-auto' : 'md:w-fit'
        }`}
        onClick={handleMobileClick}
        onMouseEnter={handleDesktopHover(true)}
        onMouseLeave={handleDesktopHover(false)}
      >
        <Image width={22} height={22} src={Whatsapp} alt="whatsapp" />
        {isExpanded ? (
          <a
            className={`inline overflow-hidden text-[14px] font-semibold text-white-500 transition-all duration-300 ${
              isExpanded ? 'md:w-auto' : 'md:w-fit'
            }`}
            href="https://api.whatsapp.com/send?phone=525559301159"
            target="_blank"
          >
            Chatea con nosotros
          </a>
        ) : null}
      </div>
    </div>
  )
}

export default WhatsappButton
